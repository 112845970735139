//import { Fancybox } from "@fancyapps/ui"; // fancybox 4
//import GLightbox from 'glightbox'; // glightbox
  
(function($) {
  // Document is ready

  // Add classes for touchscreen devices
  if ("ontouchstart" in window || "ontouch" in window) { 
    $('body').removeClass('no-touch').addClass('touch'); 
  } else {
    $('body').removeClass('touch').addClass('no-touch');
  } 

  // remove tooltips on menu items
  $(".header-menu .nav-link").each(function(){
    $(this).removeAttr('title');
  });

  // add anchor to post navigation links
  if ( $(".col-post").length > 0 ) {
    $(".pagination .nav-link").each(function(){
      $(this).attr("href",$(this).attr("href") + "#post");
    });
  }

  // blank page template
  if ( $('body.page-template-page_blank-page').length > 0 ) {
    $('.wp-block-image > a').each(function(){
      $(this).attr('data-fancybox','images');
      /*
      var caption = $(this).next().html();
      $(this).attr('data-caption',caption);
      */
    });
  }
    
  // fancybox 3
  if ( ( $('[data-fancybox]').length > 0 ) || ( $('.wp-block-image > a').length > 0 ) ) {
    $('.fancybox, .wp-block-image > a').fancybox({
      //selector: '.fancybox',
      //margin: [50,0,120,0],
      infobar :  true,
      toolbar :  true,
      buttons : [
        'zoom',
        'close'
      ],
      protect : true, //protect images from download
      image : {
          preload : "auto"
      },
      clickContent: function(current, event) {
        return current.type === "image" ? "zoom" : false;
      },
      clickSlide: "click",
      clickOutside: "click",
      animationEffect : "fade",
      animationDuration : 500,
      zoomOpacity : "auto",
      transitionEffect : "slide",
      transitionDuration : 366,
      //touch: false,
      //wheel: false,
      preventCaptionOverlap: false,
      mobile: {
        preventCaptionOverlap: false,
      },
      btnTpl : {
        close : '<button data-fancybox-close class="fancybox-button fancybox-button--close">' +
                  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
                    '<path d="M0,0l24,24 M24,0L0,24" />' +
                  '</svg>' +
                '</button>',
        zoom : '<button data-fancybox-zoom class="fancybox-button fancybox-button--zoom">' +
                  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
                    '<circle cx="9" cy="9" r="8"/><path d="M24,24l-9.344,-9.344"/><path d="M24,24l-9.344,-9.344"/><path d="M9,5l0,8" class="zoomin"/><path d="M5,9l8,0"/>' +
                  '</svg>' +
                '</button>',
        arrowLeft : '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left">&larr;</button>',

        arrowRight : '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right">&rarr;</button>'
      },
      beforeShow: function(instance, current) {
        $(".fancybox-caption").css("left","-100%");
      },
      afterShow: function(instance, current) {
        if (instance.$caption) {
          var currentSlide = $(".fancybox-slide--current .fancybox-content");
          var imageWidth = currentSlide.width();
          var imageHeight = currentSlide.height();
          var imageLeft = currentSlide.offset().left;
          
          $(".fancybox-caption").css({
            "width" :   imageWidth, 
            "marginLeft"  :   imageLeft + "px"
          }).css("left","0");

          $('video,audio').mediaelementplayer();
        }
      },
      afterLoad: function (instance, current) {
        if ( $(window).width() >= 1025 ) {
          $(current.$slide).css({
            paddingBottom: 120 + 'px',
            paddingTop: 50 + 'px'
          });
        }
        $('video,audio').mediaelementplayer();
      },
    });
  }

  $(window).on("load resize",function(){

    // scrollable content
    if (window.matchMedia('(min-width: 1025px)').matches) {
      var windowHeight = $(window).height();
      var headerHeight = $("header").height();
      var titleHeight = $(".row-title").length > 0 ? $(".row-title").height() : 0;
      var footerHeight = $("#footer-wrapper").height();
      var wpadminbar = ( $("#wpadminbar").length > 0 ) ? $("#wpadminbar").height() : 0;
      //var navHeight = $("#article-wrapper .nav").length > 0 ? $("#article-wrapper .nav").height() : 0;
      $(".simplebar").each(function(){
        var contentHeight = windowHeight - headerHeight - titleHeight - footerHeight - wpadminbar;
        $(this).height(contentHeight);
        $("html").css("overflow","hidden");
      });

      // Initialize Simplebar
      $('.simplebar').each((index, element) => new SimpleBar(element, { 
        autoHide: true,
        scrollbarMinSize: 25,
        scrollbarMaxSize: 200
      }));
    } else {
      $(".simplebar").each(function(){
        $(this).css('height','auto');
      });
    }

    // work single, submenu
    if ( $(".work-menu").length > 0 ) {
      $(".work-menu li").on("click","a",function(e){
        var thisParent = e.delegateTarget;
        $(".work-menu li").removeClass("active");
        $(thisParent).addClass("active");
        var thisTarget = $(this).attr("data-target");
        $(".work-container").removeClass("active");
        $(thisTarget).addClass("active");
        var thisTop = $(thisTarget).offset().top;
        var simplebarContainer = document.querySelector('.work-content .simplebar-content-wrapper'); 
        
        if ( $(window).innerWidth() >= 576 ) {
          simplebarContainer.scrollTo({ top: 0, behavior: "auto" });
        } else {          
          simplebarContainer.scrollTo({ top: thisTop, behavior: "auto" });
        }
      });
    }

    // work single, subsubmenu exhibitions
    if ( $(".work-rel-titles").length > 0 ) {
      $(".work-rel-title").on("click",function(e){
        e.preventDefault();
        $(".work-rel-title").removeClass("active");
        $(this).addClass("active");
        var thisTarget = $(this).attr("data-target");
        $(".work-exhibition-images").removeClass("active");
        $(thisTarget).addClass("active");
        var simplebarContainer = document.querySelector('.work-content .simplebar-content-wrapper'); 
        simplebarContainer.scrollTo({ top: 0, behavior: "auto" });
        return false;
      })
    }

    // masonry
    if ( $(".masonry").length > 0 ) {
      var $container = $('.masonry');
      $container.imagesLoaded(function(){
          $container.masonry({
            itemSelector: '.masonry-item', // select none at first
            columnWidth: '.grid-sizer',
            gutter: 10,
            horizontalOrder: true,
            // nicer reveal transition
            visibleStyle: { transform: 'translateY(0)', opacity: 1 },
            hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
          });
          $('.masonry-item img').lazyload({
              effect: 'fadeIn'
          });
          $('.masonry-item img').trigger('scroll');
      });
    }


    // adjust fancybox caption width on resize
    if ( $(".fancybox-slide--current").length > 0 ) {
      var currentSlide = $(".fancybox-slide--current .fancybox-content");
      var imageWidth = currentSlide.width();
      var imageLeft = currentSlide.offset().left;
      $(".fancybox-caption").css({
        "width" :   imageWidth, 
        "marginLeft"  :   imageLeft + "px",
      });
    }

    $('video,audio').mediaelementplayer(/* Options */);

  }).trigger('resize');


})( jQuery );